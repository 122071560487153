
.expertise-container {
  margin: 0 auto;
}

.expertise-tabbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 49px 0px;
  padding: 0 10px;
  border-bottom: 1px solid #EBEBEB;
  position: relative;
}

.expertise-tabbar-button {
  color: #020303;
  text-align: center;
  font-family: SF Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 26px;
  cursor: pointer;
  padding-bottom: 11px;
}

.expertise-tabbar-line {
  width: 196px;
  height: 2px;
  flex-shrink: 0;
  background: #2A3FCC;
  position: absolute;
  bottom: 0;
  transition: all 0.25s;
}

.expertise-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  background-color: #E7F5FE;
  padding: 51px 98px 50px 47px;
}