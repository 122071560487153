
.schedule-container {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.schedule-title {
  color: #020303;
  font-family: SF Pro;
  font-size: 42px;
  font-style: normal;
  font-weight: 590;
  line-height: 55px;
}

.schedule-prompt {
  color: #020303;
  font-family: SF Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  padding: 33px 0px 20px 0px;
}

.schedule-question-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.point-container {
  width: 34px;
  margin-right: 24px;
}

.schedule-question-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.schedule-question-title {
  color: #020303;
  font-family: SF Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.from-table {
 background-color: #FAFAFA;
 padding: 42px 60px;
 width: 660px;
 box-sizing: border-box;
 flex-shrink: 0;
}