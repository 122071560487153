
.web-dev-container {
  width: 100vw;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 59.76%, #E9EFFE 100%);
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.web-dev-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 140px;
  padding-top: 80px;
}

.web-left-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
}

.web-left-table-item {
  width: 291px;
  padding: 20px 24px;
  color: #020303;
  /* Paragraph/Medium/Medium */
  font-family: SF Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 24px;
  cursor: pointer;
  background-color: transparent;
}

.web-left-table-item-select {
  background-color: #EAEDFF;
}

.web-right-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  width: 611px;
}

.web-right-table-title {
  color: #020303;

  /* Heading/H3/Bold/Desktop */
  font-family: SF Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.web-right-table-description {
  color: #020303;

  /* Paragraph/Medium/Medium */
  font-family: SF Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: 24px; 
}

.web-right-sub-table {
  color: #020303;

/* Paragraph/Large/Medium */
font-family: SF Pro;
font-size: 18px;
font-style: normal;
font-weight: 510;
line-height: 28px;
}

.web-right-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.web-right-item-title {
  
  color: #020303;

/* Paragraph/Small/Medium */
font-family: SF Pro;
font-size: 14px;
font-style: normal;
font-weight: 510;
line-height: 20px;
}