.footer-container {
  padding: 64px;
}

.footer-logo {
  width: 143px;
}

.address-text {
  padding-top: 32px;
  padding-bottom: 64px;
  color: var(--neutral-700, #404040);
  /* Paragraph/Small/Medium */
  font-family: SF Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 510;
  line-height: 20px; 
}

.button-container {
  display: flex;
  height: 52px;
  padding-top: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 20px;
  border-top: 1px solid #E5E5E5;
}

.text-button {
  color: var(--neutral-500, #737373);
  /* Paragraph/Small/Regular */
  font-family: SF Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

