.web-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 89px;
  padding-top: 72px;
}

.web-item {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  width: 245px;
  height: 260px;
  box-sizing: border-box;
  gap: 16px;
  border-radius: 12px;
  background: #FFF;
  /* Drop shadow/Medium */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);
}
.web-item-logo{
  width: 72px;
  height: 72px;
}

.web-item-title{
  color: var(--neutral-900, #171717);
text-align: center;
font-family: Plus Jakarta Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 175% */
letter-spacing: -0.32px;
}

.web-item-description{
  color: var(--neutral-600, #525252);
/* Paragraph/XSmall/Medium */
font-family: SF Pro;
font-size: 12px;
font-style: normal;
font-weight: 510;
line-height: 20px;
}