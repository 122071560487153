
.show-body {
  padding: 72px 0px 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  width: 1120px;
  margin: 0 auto;
}

.show-column-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.show-item {
  display: flex;
  flex-direction: column;
}

.show-item-logo{
  width: 530px;
  height: 330px;
}

.show-item-min-logo{
  width: 102px;
  height: 30px;
  margin-top: 24px;
}

.show-item-max-logo{
  width: 700px;
}

.show-item-title{
  color: #020303;

  font-family: SF Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; 
  margin-top: 10px;
  letter-spacing: -1.2px;

}

.show-item-description{
  color: #020303;
  font-family: SF Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
}