.App {
  background-color: white;
  padding-bottom: 20px;
  min-width: 1200px;
}

.App-container {
  background-color: white;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #000;
  background: linear-gradient(180deg, #CBD8FA 0, #FFF 360px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Page-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.max-width {
  max-width: 1200px;
  width: 100%;
}

.Page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 128px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #2A3FCC;
  cursor: pointer;
}

.button-text {
  color: #FFF;
  text-align: justify;
  /* Paragraph/Small/Medium */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.column-title {
  color: #000;

  text-align: center;
  /* Heading/H1/Semibold/Desktop */
  font-family: SF Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 590;
  line-height: 64px; /* 120% */
  letter-spacing: -0.8px;
}

.column-description {
  color: var(--neutral-500, #737373);
  text-align: center;
  /* Heading/H6/Medium/Desktop */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
  width: 674px;
  margin: 24px auto 0px;
}

button {
  text-transform: none !important;
}