.banner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 72px 0px;
}

.banner-left-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.banner-title {
  color: #000;
  /* Heading/H1/Semibold/Desktop */
  font-family: SF Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 590;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
  padding-bottom: 24px;
}

.banner-prompt {
  color: #000;

  /* Heading/H6/Medium/Desktop */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
  padding-bottom: 32px;
}

.logo {
  width: 659px;
  height: 469px;
}
